import React from 'react'
import { WeekdayProps } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { getReadableShortWeekdayName } from '../constants'

export function WeekdayUi(props: WeekdayProps) {
  const { t } = useTranslation()

  return (
    <th ref={props.ref as any} {...props}>
      {t(getReadableShortWeekdayName(Number(props['aria-label'])))}
    </th>
  )
}
