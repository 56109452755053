import { Stripe } from '@stripe/stripe-js'
import { useRollbar } from '@rollbar/react'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  BadRequest,
  InternalError,
  NetworkError,
  ServiceUnavailable,
} from 'common/errors'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { optInFail } from 'publisher/actions/optInActions'
import { confirmPaymentIntent, buyMainOffer } from 'publisher/api/stripe'
import { IntentTypeEnum } from 'publisher/enums/IntentTypeEnum'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import usePage, { selectors as pageSelectors } from '../usePage'
import usePaymentSubmit from './usePaymentSubmit'

const getStripeConfirmHandler = (stripe: Stripe, type: IntentTypeEnum) =>
  type === IntentTypeEnum.setup
    ? stripe.confirmCardSetup
    : stripe.confirmCardPayment

const useStripeCardPaymentHandler = (
  entity: OldEntityInterface | PaymentButtonInterface,
) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const stripe = useStripe()
  const elements = useElements()
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const isReady = !!stripe && !!elements

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    await submit(async body => {
      try {
        // does not actually submit anything, validates stripe elements
        // https://docs.stripe.com/js/elements/submit
        const { error: initError } = await elements.submit()

        if (initError) {
          throw new Error('Stripe elements validation failed')
        }

        const cardElement = elements.getElement(CardNumberElement)

        if (!cardElement) {
          setErrors([t('core.errors.stripe_data_error')])
          return
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })

        if (error) {
          // @see https://systeme-team.slack.com/archives/C6UURE03D/p1670840092361989?thread_ts=1668177169.203109&cid=C6UURE03D
          setErrors([error.message as string])
          // setErrors([
          //   t('components.payment_methods.stripe.invalid_card_details'),
          // ])
          return
        }

        if (!paymentMethod) {
          throw new Error('undefined payment method')
        }

        const { data: mainOfferData } = await buyMainOffer(
          pageId,
          purchaseProcessId,
          {
            payment_form: {
              ...body,
              paymentMethodId: paymentMethod.id,
            },
          },
        )

        if (mainOfferData.intentSecret) {
          const mainOfferStripeConfirmHandler = getStripeConfirmHandler(
            stripe,
            mainOfferData.intentType,
          )

          const { error } = await mainOfferStripeConfirmHandler(
            mainOfferData.intentSecret,
            { payment_method: paymentMethod.id },
          )

          if (!error) {
            const { data: bumpData } = await confirmPaymentIntent(
              pageId,
              purchaseProcessId,
            )
            // bump case
            if (bumpData.intentSecret) {
              const bumpOfferStripeConfirmHandler = getStripeConfirmHandler(
                stripe,
                bumpData.intentType,
              )

              const { error } = await bumpOfferStripeConfirmHandler(
                bumpData.intentSecret,
                { payment_method: paymentMethod.id ?? { card: cardElement } },
              )

              if (!error) {
                const { data } = await confirmPaymentIntent(
                  pageId,
                  purchaseProcessId,
                )
                window.location.assign(data.redirect)
                return
              } else {
                rollbar.error('stripe bump offer error', error)
                setErrors([error.message as string])
              }
            }

            // redirect to upSell or thank you page
            window.location.assign(mainOfferData.redirect)
          } else {
            rollbar.error('stripe main offer error', error)
            setErrors([error.message as string])
          }
        } else {
          // redirect to upSell or thank you page
          window.location.href = mainOfferData.redirect
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (
          error instanceof InternalError ||
          error instanceof ServiceUnavailable
        ) {
          setErrors([t('core.error.title')])
        } else {
          setErrors([t('core.errors.went_wrong_contact_support')])
          rollbar.captureEvent(body, 'debug')
          rollbar.error(error as Error)
        }
      }
    })
  }

  return { errors, isReady, isLoading, handleSubmit }
}

export default useStripeCardPaymentHandler
